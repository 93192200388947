import { Order } from '../../../apps/order/models/Order';
import { OrderedItem } from '../../../apps/order/models/OrderedItem';
import APIResponse from '../../models/APIResponse';
import {AnalyticsTracking} from '../Analytics';
import {wait} from '../../utils/utils';

export default class AmazonTracker implements AnalyticsTracking {
    async amznCode() {
        if (navigator && navigator.doNotTrack == '1') {
            return null;
        }

        if (!window['amzn']) {
            while(!window['amzn']) {
                await wait(1000);
            }
        }

        return window['amzn'];
    }

    async trackPage(page: string, title?: string) {
        let amzn = await this.amznCode();
        if (!amzn) {
            return;
        }

        amzn('trackEvent', 'PageView', {
            page: page
        })
    }

    async trackCheckout(order: Order, ordered_items: APIResponse<OrderedItem>) {
        let amzn = await this.amznCode();
        if (!amzn) {
            return;
        }

        for (const item of ordered_items.items) {
            amzn('trackEvent', 'Purchase', {
                value: item.unit_price,
                currencyCode: "USD",
                unitsSold: item.quantity,
                contentId: item.product_sku,
                clientDedupeId: item.id
            })
        }
    }

    async trackAddedProductToCart(product_id: any, price: any, quantity: any) {
        let amzn = await this.amznCode();
        if (!amzn) {
            return;
        }

        amzn('trackEvent', 'AddToCart', {
            value: price,
            currencyCode: "USD",
            unitsSold: quantity,
            contentId: product_id,
        })
    }

    trackProductPage(sku: any, default_price: any) {}
    trackEvent(category: any, action: any, label?: any, value?: any) {}
}