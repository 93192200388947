import {EventDispatcher} from 'simple-ts-event-dispatcher';
import {APIResource, IAPIResourceCallback} from './APIResource';
import MessageList from '../utils/MessageList';
import {Services} from './../services/Services';
import {IModel} from './IModel';
import {Field, field} from './fields/Field';
import {Collection} from './Collection';
import ModelSyncService from '../services/ModelSyncService';
import {Http} from '../services/Http';
import {EPromiseStates, IDeferred, IPromise, SimplePromise} from '../utils/SimplePromise';
import {processRequestError} from '../utils/utils';

class ModelDataAccessor {
    constructor(
        private model: Model,
        private accessor: string
    ) {
        for (const field of model.getFields()) {
            const instance = model.getField(field);
            const getter = function () {
                return instance[accessor];
            };
            Object.defineProperty(this, field, {
                get: getter
            });
        }
    }
}

export enum SyncSetting {
    LOG = 0,
    OVERRIDE = 1,
}

export type TModelDefinitionList = { [key: string]: typeof Model; };

export class InvalidModelError extends Error {
    public override name = 'InvalidModelError';
    constructor (message) {
        super(message);
    }
}

export class Model extends EventDispatcher implements IModel {
    static uri: string;
    static objects: APIResource<Model>;
    static collectionClass: any = Collection;
    static resource_name: string;
    static field_config = {};
    static cached_field_config_union;

    private _loading: boolean;
    _errors: MessageList;
    _hasErrors: boolean;
    resource: any;
    pkField: string = 'id';

    private _lastData: any;
    private _cleaned: ModelDataAccessor;
    private deferred: IDeferred<any>;
    private socket: WebSocket;
    private change_detection_disabled: boolean;
    public sync_setting: SyncSetting;
    public modified_on_server: boolean;
    public fields = [];
    public initialized: boolean;

    @field()
    id: number;

    @field()
    resource_uri: string;

    // Optional, needed for syncing data via websocket
    @field(Field, {
        readOnly: true
    })
    content_type_id: number;

    constructor(data?: Object) {
        super();
        /* You can override the field setter by adding _ to the field name
         * on the model. You can then access the field getter/setter with
         * model._field and create your own model.field getter and/or setter
         *
         * Notes:
         *     - Each Field adds it's string name to __fields__ with the @field decorator.
         *     - Model.field_name is an array of [FieldClass, field config, override_getter/setter boolean]
         */
        this._loading = false;
        this._hasErrors = false;
        this.sync_setting = SyncSetting.OVERRIDE;

        let config_union = (this.constructor as typeof Model).field_config_union;

        this.fields = Object.keys(config_union);
        for (const field of this.fields) {
            const fieldType = config_union[field].field;
            const config = config_union[field].config;
            const field_instance_key = '__' + field;
            const field_override_key = '_' + field;
            const instance = new fieldType(this, config.default, config);

            this[field_instance_key] = instance;

            const propDesc = Object.getOwnPropertyDescriptor(this, field);

            // property getter
            let fieldGetter = null;
            let fieldSetter = null;
            if (field_override_key in this) {
                fieldGetter = () => {
                    return this[field_override_key];
                };
                fieldSetter = function (newVal) {
                    this[field_override_key] = newVal;
                };
            }
            else {
                fieldGetter = () => {
                    return instance.value;
                };
                fieldSetter = function(newVal) {
                    instance.value = newVal;
                };
            }

            const getter = propDesc ? propDesc.get : fieldGetter;
            const setter = propDesc ? propDesc.set : fieldSetter;

            if (!(field_override_key in this)) {
                delete this[field_override_key];
                // Set up a setter/getter for _key on overrides
                Object.defineProperty(this, field_override_key, {
                    get: fieldGetter,
                    set: fieldSetter,
                    enumerable: true,
                    configurable: true
                });
            }

            // Create new property with getter and setter
            Object.defineProperty(this, field, {
                get: getter,
                set: setter,
                enumerable: true,
                configurable: true
            });

            instance.bind('change', (values) => {
                this.trigger('change', field, values);
                this.trigger('change:' + field, values);
            });
        }

        this.setData(data);
        this._lastData = this.getData();
        this.initialized = true;
    }

    static get field_config_union() {
        if (this == Model) {
            return this.field_config;
        }

        return {...Object.getPrototypeOf(this).field_config_union, ...this.field_config};
    }

    unresolvedPromises(promise_set=[]) {
        if (this.$promise.state == EPromiseStates.PENDING) {
            promise_set.push(this.$promise);
        }

        for (const field of this.fields) {
            const item = this[field];

            if (item instanceof Model) {
                item.unresolvedPromises(promise_set);
            }
            else if (item instanceof Array) {
                for (const i of item) {
                    if (i['unresolvedPromises']) {
                        i.unresolvedPromises(promise_set);
                    }
                }
            }
        }

        return promise_set;
    }


    get cleaned(): {[key: string]: any} {
        if (!this._cleaned) {
            this._cleaned = new ModelDataAccessor(this, 'cleaned');
        }
        return this._cleaned;
    }

    buildResourceURI(): string {
        if (!this.id || !this.pkField || !(this.constructor as any).uri) {
            return null;
        }

        const uri = (this.constructor as any).uri.replace(`:${this.pkField}`, this.id);

        if (this.resource_uri == null) {
            this.resource_uri = uri;
        }

        return uri;
    }

    static resourceURIFromID(id: string): string {
        if (!this.uri) {
            return null;
        }

        // This won't work with the pk field, but I don't think we have ever changed it from :id
        return this.uri.replace(`:id`, id);
    }

    get baseURI() {
        if (!(this.constructor as any).uri) {
            throw new InvalidModelError(`A static resource uri was not provided on the model ${this.constructor.name}`)
        }

        return (this.constructor as any).uri.split(':id/').join('')
    }

    equals(other: any): boolean {
        if (!other) {
            return false;
        }
        else if (this === other) {
            return true;
        }
        else if (typeof other === 'string' && this.resource_uri === other) {
            return true;
        }
        else if (typeof other === 'number' && this[this.pkField] === other) {
            return true;
        }
        else if (other.resource_uri && other.resource_uri === this.resource_uri) {
            return true;
        }
        else if (other.buildResourceURI && (other.buildResourceURI() === this.buildResourceURI())) {
            return true;
        }

        return false;
    }

    isNew(): boolean {
        return !this[this.pkField];
    }

    isModified(field?) {
        const oData = this._lastData,
              nData = this.getData();

        let is_modified = (key) => {
            if (this[key] && typeof this[key].isModified === 'function') {
                if (this[key].isModified()) {
                    return true;
                }
            }

            return nData[key] !== oData[key];
        }

        if (field) {
            return is_modified(field);
        }
        else {
            for (const key of this.fields) {
                if (is_modified(key)) {
                    return true;
                }
            }
            return false;
        }
    }

    modifiedFields(): string[] {
        const oData = this._lastData,
              nData = this.getData(),
              fields = [];

        for (const key of this.fields) {
            if (this[key] && typeof this[key].isModified === 'function') {
                if (this[key].isModified() && fields.indexOf(key) == -1)
                    fields.push(key);
            }
            if (nData[key] !== oData[key] && fields.indexOf(key) == -1)
                fields.push(key);
        }

        return fields;
    }

    isNull(): boolean {
        const data = this.getPostData();
        for (const key of this.fields) {
            if ([null, undefined, []].indexOf(data[key]) === -1)
                return false;
        }
        return true;
    }

    setData(data: Object) {
        if (!data) {
            return;
        }
        for (const key of this.fields) {
            if (data[key] !== undefined) {
                this[key] = data[key];
            }
        }
    }

    /*
     * Revert data to the last setData() call. Useful for forms that edit a
     * list of items and then hit cancel rather than saving the list.
     */
    revert(field?) {
        if (!field) {
            this.setData(this._lastData);
        }
        else {
            this[field] = this._lastData[field];
        }
    }

    getData() {
        const _data = {};
        for (const key of this.fields) {
            const field = this.getField(key);

            // Ignore fields that use a getter/setter without a field like @link.
            // Otherwise, the softSave() or getData() functions will load everything when it's not needed.
            if (!field) {
                continue;
            }

            const data = this[key];
            if (data == null)
                continue;

            _data[key] = data;
        }
        return _data;
    }

    getPostData(): {[key: string]: any} {
        const _data = {};
        for (const key of this.fields) {
            const field = this['__' + key];

            if (!field)
                continue;

            const data = field.getPostData();

            if (key === 'resource_uri' && !data)
                continue;

            _data[key] = data;
        }

        return _data;
    }

    getFields(): string[] {
        return this.fields;
    }

    getField<T = Field>(field: string): T {
        return this[`__${field}`] as T;
    }

    validate(): MessageList {
        this._hasErrors = false;
        this._errors = new MessageList();
        for (const field of this.fields) {
            if (!this['__' + field] || typeof this['__' + field].validate !== 'function')
                continue;

            const errors = this['__' + field].validate();
            if (errors.length > 0) {
                this._errors.add(field, errors);
                this._hasErrors = true;
            }

            // Validate all model lists that have a getData property
            if (this[field] && this[field]['getData']) {
                for (const key of Object.keys(this[field])) {
                    let item = this[field][key];
                    if (item && item['validate']) {
                        const errs = item.validate();
                        if (errs && errs.length) {
                            this._errors.merge(errs.list);
                        }
                    }
                }
            }
        }

        this.trigger('validate', this._errors);
        return this._errors;
    }

    hasErrors() {
        this.validate();
        return this._hasErrors;
    }

    get errors() {
        if (this._errors == null) {
            this._errors = new MessageList();
        }
        return this._errors;
    }

    get $promise(): IPromise<any> {
        return this.deferred ? this.deferred.promise : SimplePromise.resolve<any>(this);
    }

    get $resolved() {
        return this.deferred?.promise.state != 0;
    }

    getResourceParams() {
        const params = {};
        params[this.pkField] = this[this.pkField];
        return params;
    }

    reload(callback?: IAPIResourceCallback, error_callback?: IAPIResourceCallback): IPromise<Model> {
        if (!this[this.pkField]) {
            console.error(`Unable to reload the model without a valid id - ${this.resource_uri}`);
            return null;
        }

        let _deferred = SimplePromise.defer<Model>();
        this.deferred = _deferred;

        const model = (this.constructor as any).objects.get({id: this[this.pkField]});
        model.$promise.then((response) => {
            this.setData(model.getData());
            _deferred.resolve(this);
            this.trigger('load');
            this.trigger('sync');

            if (callback) {
                callback(response);
            }
        }, (err) => {
            _deferred.reject(err);
            this.trigger('sync');

            if (error_callback) {
                error_callback(err);
            }
        });

        return model.$promise;
    }

    static parseResourceName(uri: string) {
        if (!uri) {
            return null;
        }

        const parts = uri.split('/');

        if (parts.length >= 3) {
            return parts[parts.length - 3];
        }

        return null;
    }

    save() {
        const _deferred: IDeferred<any> = SimplePromise.defer();
        this.deferred = _deferred;

        this.trigger('presave', this);

        const _successCallback = (response) => {
            let data = null;
            if (response.data.resource_uri || response.data.id) {
                data = response.data;
            }
            else if (response.data.items && response.data.items.length == 1) {
                data = response.data.items[0];
            }
            else {
                console.error('Unknown model data response type');
            }

            this.setData(data);
            this.softSave(true);
            this.trigger('save', this);
            this.trigger('load', this);

            _deferred.resolve(this);
        };
        const _errorCallback = (error) => {
            // Should not return null but just in case
            let response = error;
            if (error && error.response) {
                response = error.response;
            }

            if (response.data) {
                Model.parseErrors(this, response, [], 0);
            }
            else {
                this.errors.merge(processRequestError(error));
            }

            //Record all errors
            _deferred.reject('Failed to save');

            return response;
        };

        if (this[this.pkField] && (this.resource_uri || this.buildResourceURI())) {
            Services.get<Http>('$http').request({
                url: this.resource_uri || this.buildResourceURI(),
                data: this.getPostData(),
                method: 'put',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(_successCallback, _errorCallback);
        } else {
            Services.get<Http>('$http').request({
                url: this.baseURI,
                data: this.getPostData(),
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(_successCallback, _errorCallback);
        }

        return _deferred.promise;
    }

    static parseErrors(model, response, visited, depth) {
        const resource = (model.constructor as any).objects;

        if (!response || !response.data) {
            model.errors.merge(processRequestError(response));
            return;
        }

        // In case of abnormally large tree depth, prevent infinite recursion by stopping without parsing any deeper
        if (depth >= 8) {
            console.error('Model with too large of tree depth');
            return;
        }

        //If this is a model with a uri
        if (resource && resource.model && resource.model.uri) {
            const res_name = resource.model.resource_name ?
                resource.model.resource_name : Model.parseResourceName(resource.model.uri);

            // Prevent visiting the same element twice so we don't add the same error multiple times or go into
            // a infinite recursive loop

            // If the model has been visited before
            let found = false;
            visited.some((item) => {
                if (item['name'] === res_name && item['id'] === model.id) {
                    found = true;

                    return true;
                }
            });
            if (found) {
                return;
            }

            // If not add the item to the list
            visited.push({
                name: res_name,
                id: model.id
            });

            // Add all errors to the model
            for (const k in response.data[res_name]) {
                if (k == null || !response.data[res_name].hasOwnProperty(k)) {
                    return;
                }
                model.errors.add(k, response.data[res_name][k]);
            }

            // Iterate over all fields and try to parse errors for those
            const fields = model.getFields();
            for (const field_name of fields) {
                if (model[field_name] != null) {
                    this.parseErrors(model[field_name], response, visited, depth + 1);
                }
            }
        }

        // If this is the root element and no errors were found but still failed
        if (depth === 0 && model.errors.length === 0) {
            model.errors.add('__all__', 'An unknown error has occurred.');
        }
    }

    softSave(propagate: boolean = false, stack = []) {
        this._lastData = this.getData();

        if (propagate) {
            for (const level of stack) {
                if (level == this) {
                    return;
                }
            }

            stack.push(this);
            for (const key of this.fields) {
                if (!this[key]) continue;

                // Model? softSave
                if (this[key] instanceof Model)
                    this[key].softSave(true, stack);

                // Collection? map(softSave)
                if (this[key] instanceof Collection)
                    this[key].map((m) => { if (typeof m.softSave === 'function') m.softSave(true, stack); });
            }
            stack.pop();
        }
    }

    delete(callback?: IAPIResourceCallback, error_callback?: IAPIResourceCallback) {
        if (!this[this.pkField]) {
            console.error(`Unable to delete the model without a valid id - ${this.resource_uri}`);
            return null;
        }
        const _deferred: IDeferred<any> = SimplePromise.defer();
        this.deferred = _deferred;

        let response = (this.constructor as any).objects.delete(this.getResourceParams());

        response.$promise.then(() => {
            _deferred.reject()
            if (callback) {
                callback(this);
            }
        }, (error) => {
            _deferred.resolve();
            if (error_callback) {
                error_callback(error);
            }
            return error;
        })

        return response.$promise;
    }

    bindToFields(event: string, fields: string[], callback) {
        for (const field of fields) {
            const _field = this['__' + field];
            if (_field)
                _field.bind(event, callback);

        }
    }

    /*
        Configure the static properties on the class. This should only be called during the registerModels setup phase.
    */
    static configure<TModel>(cls) {
        cls.objects = new APIResource(cls);
    }

    static registerModels(models: TModelDefinitionList) {
        for (const name in models) {
            const modelName: string = name;
            const model: typeof Model = models[modelName];

            // Configure static properties
            Model.configure(model);

            Services.registerClass(name, model);
        }
    }

    public enableSync($scope, mode?) {
        if (mode !== null) {
            this.sync_setting = mode;
        }

        this.$promise.then(() => {
            Services.get<ModelSyncService>('ModelSyncService').registerModel(this, $scope);
        });
    }

    public disableSync() {
        Services.get<ModelSyncService>('ModelSyncService').deregisterModel(this);
    }

    public onServerChange() {
        this.modified_on_server = true;
        this.trigger('out-of-sync');
        if (this.sync_setting === SyncSetting.OVERRIDE || this.sync_setting == null) {
            return this.reload(() => {
                this.trigger('sync-reload');
            }, null);
        }
    }
}
